import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <section>
        <h1>404: Not found</h1>
        <p>Woops... that page doesn&apos;t seem to exist.</p>
        <Link to="/" className="inline-block">
          {`< `}Back to Home Page
        </Link>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
